/* Layouts: Contact
--------------------------------------------------------------------------------------------------------------------- */
.contact {
  padding: 45px 0 0;

  @include responsive-down(tablet-small) {
    padding: 20px 0 0;
  }

  @include responsive-down(mobile-large) {
    padding: 10px 0 0;
  }
}

.contacteam {
  &__title {
    padding: 80px 0 40px;

    @include responsive-down(mobile-large) {
      padding: 80px 0 30px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding: 0 0 80px;
  }

  &__item {
    width: calc(100% / 3 - 40px);
    margin: 0 20px;

    @include responsive-down(tablet) {
      width: calc(100% / 2 - 40px);
      margin: 0 20px 50px;

      &:last-child {
        margin: 0 20px;
      }
    }

    @include responsive-down(mobile-large) {
      width: 100%;
      margin: 0 20px 30px;
    }
  }

  p {
    margin: 0;
  }

  &__name {
    font-size: 18px;
    line-height: 1.5;
  }

  &__job {
    font-style: italic;
    margin: 0 0 10px !important;
  }

  &__social {
    color: $primary;
    display: inline-flex;
    align-items: center;
    padding: 5px 0;
    width: 100%;

    i {
      font-size: 18px;
      margin: 0 10px 0 0;
    }

    &:hover {
      color: $mid-grey;
    }
  }
}
