/* Layouts: Pages: Home
--------------------------------------------------------------------------------------------------------------------- */

.homeprojects {
  padding: 90px 0 50px;
  position: relative;

  &__list {
    width: 100%;
    overflow-x: auto;
    display: block;
    white-space: nowrap;
    padding: 20px 0;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include responsive-down(mobile-extra-small) {
      padding: 10px 0 20px;
    }
  }

  &__itemimg {
    width: 100%;
    height: 320px;
    object-fit: cover;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition (all 1.5s ease 0s);
    }

    @include responsive-down(tablet-small) {
      height: 340px;
    }

    @include responsive-down(tablet-extra-small) {
      height: 310px;
    }

    @include responsive-down(mobile-large) {
      height: 230px;
    }

    @include responsive-down(mobile) {
      height: 200px;
    }

    @include responsive-down(mobile-extra-small) {
      height: 170px;
    }
  }

  &__item {
    position: relative;
    display: inline-table;
    margin: 0 20px 0 0;
    vertical-align: top;
    cursor: pointer;
    width: 440px;
    transition: none;

    @include responsive-down(tablet-small) {
      width: 460px;
      height: 340px;
    }

    @include responsive-down(tablet-extra-small) {
      width: 430px;
      height: 310px;
    }

    @include responsive-down(mobile-large) {
      width: 350px;
      height: 230px;
    }

    @include responsive-down(mobile) {
      width: 270px;
      height: 200px;
    }

    @include responsive-down(mobile-extra-small) {
      width: 240px;
      height: 170px;
    }

    &:first-child {
      margin-left: calc(((100vw - 1920px) / 2) + 230px);

      @include responsive-down(desktop-large) {
        margin-left: calc(((100vw - 1920px) / 2) + 379px);
      }

      @include responsive-down(desktop) {
        margin-left: calc(((100vw - 1440px) / 2) + 138px);
      }

      @include responsive-down(desktop-small) {
        margin-left: calc(((100vw - 1250px) / 2) + 44px);
      }

      @include responsive-down(tablet-extra-large) {
        margin-left: 50px;
      }

      @include responsive-down(tablet) {
        margin-left: 30px;
      }

      @include responsive-down(mobile-large) {
        margin-left: 20px;
      }
    }

    p {
      margin: 0;
    }

    &:hover {
      .homeprojects__itemimg {
        box-shadow: 8px 0 16px 0 rgba(0, 0, 0, 0.1);

        img {
          transform: scale(1.1);

          @include transition (all 1s ease 0s);
        }
      }
    }
  }

  &__itemtitle {
    color: black;
    margin: 7px 0 5px;
    text-transform: initial;
    white-space: break-spaces;
    line-height: 1.4;
    font-size: 19px;

    @include responsive-down(mobile-extra-small) {
      font-size: 16px;
    }
  }

  &__location {
    font-size: 13px;
    line-height: 1.4;
    margin: 3px 0 0 !important;
  }

  &__description {
    padding: 14px 0 0 0;

    p {
      font-size: 11px;
      line-height: 18px;
      white-space: break-spaces;
    }
  }

  .btn {
    padding: 13px 30px;
  }
}

.homeproduction {
  margin: 75px 0 170px;
  position: relative;
  display: flex;

  @include responsive-down(tablet-small) {
    margin: 20px 0 170px;
  }

  @include responsive-down(mobile-extra-large) {
    padding: 60px 0;
    flex-direction: column;
    margin: 20px 0 0;
  }

  @include responsive-down(mobile-small) {
    padding: 37px 0 50px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 420px;
    background-color: $light-grey;
    position: absolute;
    top: 60px;
    z-index: -1;

    @include responsive-down(mobile-extra-large) {
      height: 100%;
      top: 0;
    }
  }

  &__intro {
    max-width: 340px;
    margin-left: calc(((100vw - 1920px) / 2) + 230px);
    margin-top: 145px;

    @include responsive-down(desktop-large) {
      margin-left: calc(((100vw - 1920px) / 2) + 385px);
    }

    @include responsive-down(desktop) {
      margin-left: calc(((100vw - 1440px) / 2) + 145px);
    }

    @include responsive-down(desktop-small) {
      margin-left: calc(((100vw - 1180px) / 2) + 15px);
    }

    @include responsive-down(tablet-extra-large) {
      margin-left: 50px;
    }

    @include responsive-down(tablet) {
      margin: 115px 0 0 30px;
    }

    @include responsive-down(mobile-extra-large) {
      margin: 0 0 0 30px;
      max-width: 400px;
    }

    @include responsive-down(mobile-large) {
      margin: 0 20px;
    }

    @include responsive-down(mobile-small) {
      max-width: 100%;
    }

    @include responsive-down(mobile-extra-small) {
      max-width: none;
      width: auto;
    }
  }

  &__title {
    line-height: 1.3;
    font-size: 26px;

    @include responsive-down(mobile) {
      font-size: 24px;
      line-height: 1.4;
    }

    @include responsive-down(mobile-small) {
      font-size: 23px;
      line-height: 1.4;
      max-width: 100%;
    }

    @include responsive-down(mobile-extra-small) {
      font-size: 20px;
      max-width: none;
    }
  }

  &__text {
    margin: 24px 0;

    @include responsive-down(mobile-small) {
      margin: 18px 0 15px;
    }
  }

  .btn {
    display: inline-flex;
    align-items: center;

    i {
      margin-left: 10px;
      font-size: 18px;
    }
  }

  &__gallery {
    width: 100%;
    overflow-x: auto;
    display: block;
    white-space: nowrap;
    margin: 0 0 0 50px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include responsive-down(tablet) {
      margin: 0 0 0 30px;
    }

    @include responsive-down(tablet-small) {
      margin: 30px 0 0 50px;
    }

    @include responsive-down(mobile-extra-large) {
      margin: 50px 0 0 30px;
      width: auto;
    }

    @include responsive-down(mobile-large) {
      margin: 50px 0 0 20px;
    }

    @include responsive-down(mobile-small) {
      margin: 45px 0 0 20px;
    }
  }

  &__img {
    width: 350px;
    height: 430px;
    position: relative;
    display: inline-table;
    margin: 0 20px 0 0;
    vertical-align: top;
    cursor: pointer;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    @include responsive-down(tablet) {
      width: 320px;
      height: 400px;
    }

    @include responsive-down(tablet-small) {
      width: 280px;
      height: 360px;
    }

    @include responsive-down(mobile-extra-large) {
      width: 240px;
      height: 300px;
    }

    @include responsive-down(mobile) {
      width: 220px;
      height: 270px;
      margin: 0 10px 0 0;
    }

    &:hover {
      .img {
        transform: scale(1.1);

        @include transition (all 0.5s ease 0s);
      }
    }
  }
}
