/* Layouts: About us
--------------------------------------------------------------------------------------------------------------------- */

.aboutcompany {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 100px;

  @include responsive-down(mobile-extra-large) {
    flex-direction: column;
    margin: 0 0 50px;
  }

  &__content {
    max-width: 460px;
    padding: 0 0 30px;

    @include responsive-down(tablet) {
      max-width: 370px;
    }

    @include responsive-down(tablet-extra-small) {
      max-width: 330px;
    }

    @include responsive-down(mobile-extra-large) {
      max-width: none;
      width: 100%;
    }
  }

  &__imgoutside {
    width: 600px;
    height: 380px;
    overflow: hidden;
    margin: 0 0 0 30px;
    object-fit: cover;
    position: relative;

    &::before {
      content: "";
      background-color: $mid-grey;
      width: 100%;
      height: 100%;
      position: absolute;
      transform: translateX(125%);
      transition: transform 0.3s cubic-bezier(0.59, 0.08, 0.385, 1);

      @include transition (all 1.5s ease 0s);
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      margin: 0;
      height: 350px;
    }

    @include responsive-down(mobile) {
      height: 330px;
    }

    @include responsive-down(mobile-small) {
      height: 270px;
    }
  }

  &__imginside {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: grid;
    object-fit: cover;
    transform: scale(1.5) translateX(125%);
    opacity: 0.5;

    @include transition (all 2s ease 0s);

    img {
      width: 100%;
      height: 100%;
      display: grid;
      object-fit: cover;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      margin: 0;
      height: 350px;
    }

    @include responsive-down(mobile) {
      height: 330px;
    }

    @include responsive-down(mobile-small) {
      height: 270px;
    }
  }
}

.aboutteam {
  display: flex;
  align-items: center;
  margin: 0 0 100px;

  @include responsive-down(mobile-extra-large) {
    flex-direction: column-reverse;
    margin: 0 0 50px;
  }

  &__content {
    max-width: 440px;
    margin: 0 0 0 100px;

    @include responsive-down(tablet-large) {
      margin: 0 0 0 80px;
    }

    @include responsive-down(tablet) {
      margin: 0 0 0 60px;
      max-width: 370px;
    }

    @include responsive-down(tablet-small) {
      max-width: 340px;
      margin: 0 0 0 40px;
    }

    @include responsive-down(tablet-extra-small) {
      max-width: 330px;
    }

    @include responsive-down(mobile-extra-large) {
      max-width: none;
      width: 100%;
      margin: 0 0 40px;
    }
  }

  &__imgoutside {
    width: 550px;
    height: 380px;
    overflow: hidden;
    object-fit: cover;
    position: relative;

    &::before {
      content: "";
      background-color: $mid-grey;
      width: 100%;
      height: 100%;
      position: absolute;
      transform: translateX(-125%);
      transition: transform 0.3s cubic-bezier(0.59, 0.08, 0.385, 1);

      @include transition (all 1.5s ease 0s);
    }

    @include responsive-down(mobile-extra-large) {
      width: 300px;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      height: 350px;
    }

    @include responsive-down(mobile) {
      height: 330px;
    }

    @include responsive-down(mobile-small) {
      height: 270px;
    }
  }

  &__imginside {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: block;
    object-fit: cover;
    transform: scale(1.5) translateX(-125%);
    opacity: 0.5;

    @include transition (all 2s ease 0s);

    img {
      width: 100%;
      height: 100%;
      display: grid;
      object-fit: cover;
    }
  }

  &__text {
    @include responsive-down(mobile-extra-large) {
      margin: 1em 0 30px;
    }
  }
}

.aboutclients {
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 50px auto 100px;

  &__content {
    background-color: $mid-grey;
    padding: 60px 0;

    @include responsive-down(mobile) {
      padding: 25px 0;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include responsive-down(tablet-small) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .content {
      &-info {
        max-width: 440px;

        @include responsive-down(tablet) {
          max-width: 340px;
        }

        @include responsive-down(tablet-small) {
          max-width: 600px;
        }

        &__title,
        &__text {
          color: white;
        }

        &__text {
          margin: 0;
        }

        .titleh2 {
          margin: 0 0 10px;
        }
      }

      &-list {
        max-width: 550px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -10px;

        @include responsive-down(tablet-small) {
          max-width: 100%;
          justify-content: flex-start;
          margin: 30px -10px 0 0;
        }

        @include responsive-down(mobile-large) {
          max-width: 80%;
        }

        @include responsive-down(mobile-small) {
          max-width: 100%;
          justify-content: space-between;
        }

        &__item {
          width: 130px;
          object-fit: contain;
          margin: 10px;

          @include responsive-down(tablet-small) {
            width: calc(100% / 4 - 20px);
          }

          @include responsive-down(mobile-large) {
            width: calc(100% / 2 - 20px);
          }

          @include responsive-down(mobile-small) {
            width: calc(100% / 2 - 10px);
          }

          &:nth-child(2n+1) {
            @include responsive-down(mobile-small) {
              margin-left: 0;
            }
          }

          &:nth-child(2n) {
            @include responsive-down(mobile-small) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &__bgimg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: 50% 80%;
    background-repeat: no-repeat;

    @include responsive-down(mobile-extra-large) {
      background-image: url(/assets/img/about/aboutus-parallax-mobile.jpg) !important;
    }
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 200px;
    overflow: hidden;

    @include responsive-down(mobile) {
      height: 330px;
    }

    @include responsive-down(mobile-extra-small) {
      height: 300px;
    }

    img {
      width: 100%;
      display: grid;
      object-fit: cover;
      max-height: 400px;

      @include transition (all 0.5s ease 0s);
    }
  }
}

/**
  animation slide-right
*/
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
