/* Breakpoints
/-------------------------------------------------------------------------------------------------------------------- */
$icon: 'icomoon' !important;

$desktop-extra-large: 2560px;
$desktop-large: 1920px;
$desktop: 1600px;
$desktop-small: 1440px;
$desktop-extra-small: 1366px;
$tablet-extra-large: 1280px;
$tablet-large: 1080px;
$tablet: 991px;
$tablet-small: 860px;
$tablet-extra-small: 768px;
$mobile-extra-large: 720px;
$mobile-large: 640px;
$mobile: 540px;
$mobile-small: 480px;
$mobile-extra-small: 375px;

/* Typography
--------------------------------------------------------------------------------------------------------------------- */
$font-icomoon: 'icomoon', sans-serif;
$letter-spacing: 0.03rem;
$letter-spacing-2x: 0.06rem;
$letter-spacing-big: 0.1rem;

/* Color
/-------------------------------------------------------------------------------------------------------------------- */
$primary: #4b4b4b;
$mid-grey: #afafaf;
$light-grey: #f5f5f5;
$white: #fff;
$beige: #d8c29f;
$beige03: rgba(216, 194, 159, 0.9);
$dark-grey: #4a4a4a;
$light-black: #222;
$mid-black: #111;
$black: #000;
$black02: rgba(0, 0, 0, 0.2);
$black05: rgba(0, 0, 0, 0.5);
$black07: rgba(0, 0, 0, 0.7);

// TIPOS
$font-operetta: 'operetta-12', serif;
$font-urw: 'urw-form', sans-serif;

// COLORS: Text
$text-menu: $mid-grey;
$post-color: $dark-grey;
$text-color: #757575;
$text: #a5a5a5;

// MENU FIXED
$menu-background: rgba(255, 255, 255, 0.3);
$menu-collection: rgba(245, 245, 245, 0.5);

// COLORS: Text terms footer
$link-footer: #999;

// COLORS: Alerts
$success: #8ac148;
$success-hover: #599014;
$info: #00a9f4;
$info-hover: #007ac1;
$warning: #ff4949;
$warning-hover: #c66900;

/* Transitions
/-------------------------------------------------------------------------------------------------------------------- */
$transition-slow: all 300ms ease-in-out;
$transition-medium: all 200ms ease-in;
$transition-fast: all 100ms ease-in;
