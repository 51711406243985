/* Layouts: Projects
--------------------------------------------------------------------------------------------------------------------- */
.projects {
  padding: 20px 0 0;

  @include responsive-down(tablet) {
    padding: 0;
  }

  .titleh2 {
    margin: 0 0 10px;
  }

  &-list {
    display: flex;
    justify-content: flex-start;
    margin: 0 -20px 0;
    flex-wrap: wrap;
    padding: 0 0 120px;

    @include responsive-down(mobile-extra-large) {
      padding: 0 0 30px;
    }

    &__item {
      width: calc(100% / 3 - 40px);
      margin: 0 20px 40px 20px;

      @include responsive-down(tablet) {
        width: calc(100% / 2 - 40px);
      }

      @include responsive-down(mobile-extra-large) {
        width: 100%;
      }

      .item-projects__location {
        font-size: 16px;
        margin: 0 !important;
      }
    }

    &__itemimg {
      height: 290px;

      @include responsive-down(tablet-extra-large) {
        height: 250px;
      }

      @include responsive-down(tablet-large) {
        height: 230px;
      }

      @include responsive-down(tablet) {
        height: 290px;
      }

      @include responsive-down(tablet-small) {
        height: 230px;
      }

      @include responsive-down(mobile-extra-large) {
        height: 300px;
      }

      @include responsive-down(mobile) {
        height: 250px;
      }

      @include responsive-down(mobile-extra-small) {
        height: 200px;
      }
    }
  }

  &-teamwork {
    display: flex;
    position: relative;

    @include responsive-down(mobile-extra-large) {
      flex-direction: column;
    }

    &::before {
      content: "";
      width: 100%;
      height: 200px;
      background-color: $light-grey;
      position: absolute;
      bottom: 0;
      z-index: -1;

      @include responsive-down(tablet-extra-large) {
        height: 170px;
      }

      @include responsive-down(mobile-extra-large) {
        height: 100%;
      }
    }

    &__content {
      margin-left: calc(((100vw - 1920px) / 2) + 230px);

      @include responsive-down(desktop-large) {
        margin-left: calc(((100vw - 1180px) / 2) + 30px);
      }

      @include responsive-down(tablet-extra-large) {
        margin-left: 50px;
      }

      @include responsive-down(mobile-extra-large) {
        margin: 0;
        padding: 40px 20px 30px;
      }
    }

    &__img {
      height: 350px;
      width: 1100px;

      @include responsive-down(tablet-extra-large) {
        width: 900px;
        height: 400px;
      }

      @include responsive-down(tablet-large) {
        height: 410px;
      }

      @include responsive-down(mobile-extra-large) {
        width: 100%;
        height: 300px;
      }

      @include responsive-down(mobile) {
        height: 250px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-description {
        max-width: 1100px;
        padding: 0 40px 0 0;
        line-height: 1.5;

        @include responsive-down(tablet-extra-large) {
          max-width: 480px;
          padding: 0 30px 30px 0;
        }

        @include responsive-down(mobile-extra-small) {
          max-width: 750px;
          padding: 0 0 30px 0;
        }

        &__title {
          margin: 0 0 10px;
          line-height: 1.3;

          @include responsive-down(tablet-extra-large) {
            max-width: 380px;
          }

          @include responsive-down(mobile-large) {
            font-size: 30px;
            margin: 0 0 7px;
          }

          @include responsive-down(mobile-small) {
            font-size: 30px;
            max-width: 300px;
          }
        }

        &__text {
          margin: 0;
        }
      }

      &-counters {
        display: inline-flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        height: 200px;

        @include responsive-down(tablet-extra-large) {
          height: 170px;
          margin: 0;
        }

        @include responsive-down(mobile-extra-large) {
          height: 120px;
        }

        &__item {
          margin: 0 110px 0 0;

          .number {
            font-size: 65px;
            margin: 0 0 30px;
            line-height: 29px;
            font-family: 'operetta-12', serif;

            @include responsive-down(mobile-extra-large) {
              font-size: 60px;
            }
          }

          .text {
            font-size: 20px;
            margin: 0;
            line-height: 1px;

            @include responsive-down(mobile-extra-large) {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  &-anexo {
    padding: 80px 0;
    columns: 3;
    column-gap: 70px;

    @include responsive-down(tablet-extra-small) {
      column-gap: 50px;
    }

    @include responsive-down(mobile-large) {
      columns: 2;
      padding: 60px 0 0;
    }

    &__location {
      margin: 0 0 6px !important;
      font-size: 17px !important;

      @include responsive-down(mobile-large) {
        font-size: 16px !important;
      }
    }

    p {
      margin: 0 0 6px;
      line-height: 24px;
      font-size: 15px;

      @include responsive-down(mobile-large) {
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__item {
      margin: 0 0 30px;
    }
  }
}
