/* Layouts: Production
--------------------------------------------------------------------------------------------------------------------- */

.productdescription {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 80px 0;

  @include responsive-down(tablet-small) {
    max-width: 100%;
  }

  @include responsive-down(mobile-extra-large) {
    text-align: left;
    padding: 0;
  }

  &__title {
    font-family: $font-operetta;
    line-height: 1.4;
    text-align: center;
    font-size: 28px;
    text-transform: initial;

    @include responsive-down(tablet-extra-small) {
      font-size: 26px;
    }

    @include responsive-down(mobile-extra-large) {
      text-align: left;
    }

    @include responsive-down(mobile) {
      font-size: 25px;
    }

    @include responsive-down(mobile-extra-small) {
      font-size: 20px;
    }
  }

  &--two {
    max-width: 400px;

    @include responsive-down(mobile-extra-large) {
      text-align: left;
      margin: 0;
      padding: 0;
    }
  }

  &--environmental {
    max-width: 1000px;
    padding: 80px 0;

    @include responsive-down(tablet-small) {
      padding: 80px 0 40px;
    }
  }
}

.product3columns {
  display: flex;
  margin: 0 -20px;

  @include responsive-down(tablet) {
    flex-wrap: wrap;
  }

  @include responsive-down (mobile-extra-large) {
    padding: 20px 0 80px;
  }

  &__item {
    width: calc(100% / 3 - 40px);
    margin: 0 20px;

    @include responsive-down(tablet) {
      width: 100%;
      margin: 0 20px 30px;
    }

    @include responsive-down(mobile-large) {
      width: 100%;
      margin: 0 20px 20px;
    }

    &:last-child {
      @include responsive-down(tablet) {
        margin: 0 20px 0;
      }

      @include responsive-down(mobile-extra-large) {
        width: 100%;
        margin: 0 20px 0;
      }
    }

    p {
      margin: 0;
    }
  }

  &__img {
    height: auto;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition (all 1.5s ease 0s);
    }
  }
}

.productslider {
  display: flex;
  align-items: center;
  padding: 0 0 100px;
  justify-content: space-between;
  margin: 0 30px;

  @include responsive-down(tablet-small) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0 0 50px;
  }

  @include responsive-down (mobile-extra-large) {
    padding: 30px 0;
    margin: 0;
  }

  &__intro {
    max-width: 700px;
    padding: 0 90px 0;

    @include responsive-down(desktop-small) {
      max-width: 400px;
      padding: 0 40px 0 0;
    }

    @include responsive-down(tablet) {
      max-width: 320px;
      padding: 0 20px 0 0;
    }

    @include responsive-down(tablet-small) {
      max-width: 600px;
      padding: 20px 0;
    }

    @include responsive-down(mobile-extra-large) {
      padding: 20px;
    }

    @include responsive-down(mobile-extra-large) {
      max-width: 100%;
    }

    .titleh2 {
      @include responsive-down(mobile-large) {
        font-size: 23px;
      }

      @include responsive-down(mobile-small) {
        font-size: 20px;
      }
    }

    p {
      margin: 10px 0 0;
    }
  }

  &__introtitle {
    line-height: 1.4;
    font-size: 28px;

    @include responsive-down(tablet-extra-small) {
      font-size: 26px;
    }

    @include responsive-down(mobile) {
      font-size: 23px;
    }
  }

  &__text {
    @include responsive-down(mobile) {
      line-height: 27px;
      font-size: 15px;
    }
  }

  .tns-outer {
    max-width: 60%;
    position: relative;
    margin: 0 0 0 40px;

    @include responsive-down(tablet-small) {
      max-width: 100%;
      margin: 0;
    }

    button {
      height: 50px;
      width: 40px;
      padding: 0;
      margin: 0 4px 0;
      border: none;
      position: relative;

      @include responsive-down(tablet) {
        width: 20px;
      }

      &::before {
        content: "";
        height: 2px;
        width: 40px;
        background-color: white;
        position: absolute;
        left: 0;
        bottom: 20px;
      }
    }

    button[data-action="stop"],
    [data-action="start"] {
      display: none;
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 40;
    border: none;

    @include responsive-down(tablet) {
      bottom: 5px;
    }

    @include responsive-down(mobile) {
      bottom: 10px;
    }

    .tns-nav-active {
      &::before {
        content: "";
        background-color: $primary;
      }
    }
  }

  &__img {
    img {
      width: 100%;
      height: 500px;
      object-fit: cover;

      @include responsive-down(tablet-extra-large) {
        height: 500px;
        max-height: none;
      }

      @include responsive-down(tablet-large) {
        height: 460px;
        max-height: none;
      }

      @include responsive-down(tablet) {
        height: 400px;
        max-height: none;
      }

      @include responsive-down(mobile-large) {
        height: 350px;
      }

      @include responsive-down(mobile) {
        height: 300px;
      }

      @include responsive-down(mobile-small) {
        height: 250px;
      }
    }
  }

  .tns-item {
    width: 100%;
  }

  &--reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;

    @include responsive-down(tablet-small) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .tns-outer {
      margin: 0 40px 0 0;

      @include responsive-down(tablet-small) {
        max-width: 100%;
        margin: 0;
      }
    }
  }
}

.productcontrols {
  display: flex;
  background-color: $light-grey;
  align-items: center;
  position: relative;

  @include responsive-down(tablet-small) {
    flex-direction: column;
  }

  &__content {
    width: 50%;
    padding: 50px 30px;

    @include responsive-down(tablet-small) {
      width: 100%;
    }

    @include responsive-down(mobile-large) {
      padding: 50px 20px;
    }

    p {
      margin: 20px 0;
    }
  }

  &__img {
    width: 50%;
    height: 100%;
    display: grid;
    position: absolute;
    right: 0;
    overflow: hidden;
    align-content: center;

    @include responsive-down(desktop) {
      overflow: inherit;
      align-content: inherit;
    }

    @include responsive-down(tablet-small) {
      width: 100%;
      position: relative;
      height: 400px;
    }

    @include responsive-down(mobile-extra-large) {
      height: 350px;
    }

    @include responsive-down(mobile-small) {
      height: 270px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.productcollection {
  display: flex;
  align-items: center;
  margin: 0 0 100px;

  @include responsive-down(mobile-extra-large) {
    flex-direction: column;
    margin: 50px 0;
  }

  &__content {
    max-width: 440px;
    padding: 0 40px 0 0;

    @include responsive-down(tablet) {
      max-width: 370px;
    }

    @include responsive-down(tablet-small) {
      max-width: 340px;
    }

    @include responsive-down(tablet-extra-small) {
      max-width: 320px;
    }

    @include responsive-down(mobile-extra-large) {
      max-width: none;
      width: 100%;
      margin: 0 0 40px;
    }
  }

  &__img {
    width: 100%;
    height: 380px;
    overflow: hidden;

    @include responsive-down(mobile-extra-large) {
      width: 300px;
    }

    img {
      width: 100%;
      height: 100%;
      display: grid;
      object-fit: cover;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      height: 350px;
    }

    @include responsive-down(mobile) {
      height: 330px;
    }

    @include responsive-down(mobile-small) {
      height: 270px;
    }
  }

  &__text {
    @include responsive-down(mobile-extra-large) {
      margin: 1em 0 30px;
    }
  }
}

.productvalues {
  padding: 0 0 120px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include responsive-down(tablet-large) {
    padding: 0 0 100px;
    display: block;
  }

  @include responsive-down(tablet-small) {
    padding: 0 0 80px;
  }

  @include responsive-down(mobile-extra-large) {
    padding: 0 0 70px;
  }

  &__item {
    width: auto;
    // width: calc(100% / 4 - 60px);
    margin: 0 70px;
    position: relative;
    display: inline-table;
    vertical-align: top;
    cursor: pointer;

    @include responsive-down(mobile-large) {
      width: calc(100% / 4 - 40px);
      margin: 0 20px;
    }

    i {
      font-size: 45px;
    }

    &:first-child {
      @include responsive-down(tablet-large) {
        margin: 0 70px 0 30px;
      }

      @include responsive-down(mobile-large) {
        margin: 0 20px;
      }
    }
  }

  &__title {
    font-family: $font-operetta;
    font-size: 20px;
    margin: 10px 0 0;
  }
}
