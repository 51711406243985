/* Layouts: Header
--------------------------------------------------------------------------------------------------------------------- */
.header {
  &--fixed {
    position: fixed;
    padding-top: 20px;
    padding-bottom: 20px;

    .header__brand {
      .cls-1 {
        fill: black;
      }
    }

    .menu-burger.menu-burger {
      span {
        background-color: black;
      }
    }

    .menu {
      &:hover {
        .menu-burger {
          &__top,
          &__mid,
          &__bot {
            background-color: $white;
          }
        }
      }
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $menu-background;
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }
  }
}

body.header--fixed.header--white {
  .menu-burger.menu-burger {
    span {
      background-color: black;
    }
  }
}
