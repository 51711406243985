/* Layouts: Project Card
--------------------------------------------------------------------------------------------------------------------- */

.projcardsection1 {
  display: flex;
  padding: 50px 0;

  @include responsive-down(tablet-extra-large) {
    margin: 0;
  }

  @include responsive-down(mobile-extra-large) {
    flex-wrap: wrap;
    padding: 30px 0;
  }

  &__left,
  &__right {
    width: calc(100% / 2 - 60px);
    margin: 0 30px;

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      margin: 0;
    }

    p {
      margin: 10px 0 50px;

      @include responsive-down(mobile) {
        margin: 10px 0 20px;
      }
    }

    .projcardsection1__content {
      @include responsive-down(mobile-extra-large) {
        margin: 0 30px;
      }

      @include responsive-down(mobile-large) {
        margin: 0 20px;
      }
    }
  }

  &__img {
    height: 580px;
    width: auto;

    @include responsive-down(tablet-large) {
      height: 450px;
    }

    @include responsive-down(tablet) {
      height: 400px;
    }

    @include responsive-down(tablet-extra-small) {
      height: 350px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition (all 1.5s ease 0s);
    }
  }

  &__left {
    margin-left: calc(((100vw - 1250px) / 2) + 50px);

    @include responsive-down(tablet-extra-large) {
      margin-left: 30px;
    }

    @include responsive-down(mobile-extra-large) {
      margin: 0;
    }

    .projcardsection1__img {
      @include responsive-down(mobile-extra-large) {
        margin-right: 30px;
      }

      @include responsive-down(mobile-large) {
        margin-right: 20px;
      }
    }
  }

  &__right {
    padding: 220px 0 0;
    margin-right: calc(((100vw - 1250px) / 2) + 50px);

    @include responsive-down(tablet-extra-large) {
      margin-right: 30px;
    }

    @include responsive-down(mobile-extra-large) {
      padding: 40px 0 0;
      margin-right: 0;
    }

    .projcardsection1__img {
      @include responsive-down(mobile-extra-large) {
        margin-left: 30px;
      }

      @include responsive-down(mobile-large) {
        margin-left: 20px;
      }
    }
  }

  &__title {
    line-height: 1.4;
    font-size: 28px;

    @include responsive-down(tablet-extra-small) {
      font-size: 26px;
    }

    @include responsive-down(mobile) {
      font-size: 23px;
    }
  }
}

.projcardsectionvideo {
  position: relative;
  overflow: hidden;
  margin: 50px 0;

  @include responsive-down (mobile-extra-large) {
    margin: 30px 0;
  }

  &::before {
    content: "";
    float: left;
    width: 100%;
    padding-top: 600px;

    @include responsive-down(tablet) {
      padding-top: 500px;
    }

    @include responsive-down(mobile-extra-large) {
      padding-top: 450px;
    }

    @include responsive-down(mobile-small) {
      padding-top: 400px;
    }
  }

  &__videoContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    video {
      height: calc(100% - 35px);
      width: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }

  &__preview {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: #fff;
      text-transform: uppercase;
      position: absolute;
      z-index: 2;
      display: inline-block;
      font-size: 13px;
      font-weight: 700;

      @include responsive-down(mobile-small) {
        font-size: 12px;
      }

      &::before {
        content: "";
        height: 1px;
        width: 100%;
        background-color: white;
        position: absolute;
        bottom: 2px;
      }
    }

    span {
      position: absolute;
      width: 100px;
      height: 100px;
      background-color: $beige03;
      backdrop-filter: saturate(180%) blur(20px);
      border-radius: 50%;
      z-index: 1;
      opacity: 1;

      @include transition (all 0.4s ease-in);

      @include responsive-down(mobile-extra-large) {
        width: 90px;
        height: 90px;
      }

      @include responsive-down(mobile-small) {
        width: 80px;
        height: 80px;
      }

      &:hover {
        width: 120px;
        height: 120px;

        @include responsive-down(mobile-extra-large) {
          width: 110px;
          height: 110px;
        }

        @include responsive-down(mobile-small) {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}

.projcard3columns {
  display: flex;
  margin: 0 -20px;
  padding: 50px 0;

  @include responsive-down(tablet) {
    flex-wrap: wrap;
  }

  @include responsive-down (mobile-extra-large) {
    padding: 30px 0;
  }

  &__item {
    width: calc(100% / 3 - 40px);
    margin: 0 20px;

    @include responsive-down(tablet) {
      width: 100%;
      margin: 0 20px 40px;
    }

    &:last-child {
      @include responsive-down(mobile-extra-large) {
        width: 100%;
        margin: 0 20px 0;
      }
    }

    p {
      margin: 0;
    }
  }

  &__img {
    height: 300px;
    width: 100%;

    @include responsive-down(tablet-extra-large) {
      height: 250px;
    }

    @include responsive-down(tablet-large) {
      height: 220px;
    }

    @include responsive-down(tablet) {
      height: 380px;
    }

    @include responsive-down(tablet-small) {
      height: 300px;
    }

    @include responsive-down(mobile-extra-large) {
      height: 250px;
    }

    @include responsive-down(mobile) {
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition (all 1.5s ease 0s);
    }
  }

  &__itemtitle {
    font-family: $font-operetta;
    font-size: 22px;
    margin: 15px 0 6px !important;

    @include responsive-down(tablet-small) {
      font-size: 20px;
    }

    @include responsive-down(mobile-extra-large) {
      font-size: 18px;
      margin: 8px 0 0 !important;
    }
  }
}

.projcard2columns {
  display: flex;
  padding: 50px 0;
  text-align: center;

  @include responsive-down(mobile-extra-large) {
    flex-wrap: wrap;
    padding: 30px 0;
  }

  &__item {
    width: calc(100% / 2 - 10px);
    margin: 0 30px;

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      margin: 0 0 40px;
    }

    p {
      margin: 0;
    }

    &:last-child {
      @include responsive-down(mobile-extra-large) {
        margin: 0;
      }
    }
  }

  &__itemtitle {
    font-family: $font-operetta;
    font-size: 22px !important;
    margin: 22px 0 10px !important;
    text-align: center;

    @include responsive-down(tablet-small) {
      font-size: 20px !important;
    }

    @include responsive-down(mobile-extra-large) {
      font-size: 18px !important;
    }
  }

  &__img {
    height: 360px;
    width: 100%;

    @include responsive-down(tablet) {
      height: 300px;
    }

    @include responsive-down(tablet-small) {
      height: 280px;
    }

    @include responsive-down(tablet-extra-small) {
      height: 250px;
    }

    @include responsive-down(mobile-large) {
      height: 280px;
    }

    @include responsive-down(mobile) {
      height: 220px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition (all 1.5s ease 0s);
    }
  }

  &__content {
    max-width: 400px;
    margin: 0 auto;

    @include responsive-down(mobile-extra-large) {
      max-width: 360px;
    }

    @include responsive-down(mobile-extra-small) {
      max-width: 300px;
    }

    p {
      line-height: 1.8;
    }
  }
}

.projcardslider {
  display: flex;
  align-items: center;
  padding: 50px 0;
  justify-content: space-between;
  margin: 0 30px;

  @include responsive-down(tablet-small) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include responsive-down (mobile-large) {
    padding: 30px 0;
    margin: 0;
  }

  &__intro {
    max-width: 600px;
    padding: 0 100px 0;

    @include responsive-down(desktop-small) {
      max-width: 400px;
      padding: 0 60px 0 0;
    }

    @include responsive-down(tablet) {
      max-width: 320px;
      padding: 0 20px 0 0;
    }

    @include responsive-down(tablet-small) {
      max-width: 600px;
      padding: 0 0 20px;
    }

    @include responsive-down(mobile-large) {
      max-width: 100%;
      padding: 0 20px 20px;
    }
  }

  &__introtitle {
    line-height: 1.4;
    font-size: 28px;

    @include responsive-down(tablet-extra-small) {
      font-size: 26px;
    }

    @include responsive-down(mobile) {
      font-size: 23px;
    }
  }

  &__text {
    @include responsive-down(mobile) {
      line-height: 27px;
      font-size: 15px;
    }
  }

  .tns-outer {
    max-width: 70%;
    position: relative;

    @include responsive-down(tablet-large) {
      max-width: 60%;
    }

    @include responsive-down(tablet-small) {
      max-width: 100%;
    }

    button {
      height: 2px;
      width: 40px;
      padding: 0;
      margin: 0 4px 0;
      border: none;

      @include responsive-down(tablet) {
        width: 30px;
      }
    }

    button[data-action="stop"] {
      display: none;
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 40;
    border: none;

    @include transform(rotate(180deg));

    @include responsive-down(tablet) {
      bottom: 5px;
    }

    .tns-nav-active {
      background-color: $primary;
    }
  }

  &__img {
    img {
      width: 100%;
      max-height: 500px;
      object-fit: cover;

      @include responsive-down(tablet-large) {
        height: 500px;
        max-height: none;
      }

      @include responsive-down(mobile) {
        height: 400px;
      }

      @include responsive-down(mobile-small) {
        height: 360px;
      }
    }
  }

  .tns-item {
    width: 100%;
  }
}

.projcardsliderfull {
  display: flex;
  align-items: center;
  padding: 50px 30px;
  justify-content: space-between;

  @include responsive-down(tablet-small) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include responsive-down (mobile-extra-large) {
    padding: 30px 20px;
  }

  @include responsive-down (mobile-large) {
    padding: 30px 0;
  }

  .tns-outer {
    max-width: 100%;
    position: relative;

    button {
      height: 2px;
      width: 40px;
      padding: 0;
      margin: 0 4px 0;
      border: none;

      @include responsive-down(tablet) {
        width: 30px;
      }
    }

    button[data-action="stop"] {
      display: none;
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 40;
    border: none;

    @include transform(rotate(180deg));

    @include responsive-down(tablet) {
      bottom: 5px;
    }

    .tns-nav-active {
      background-color: $primary;
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;

    img {
      object-fit: cover;
      width: 100%;
      height: 600px;

      @include responsive-down(tablet-large) {
        height: 500px;
      }

      @include responsive-down(mobile) {
        height: 400px;
      }

      @include responsive-down(mobile-small) {
        height: 360px;
      }
    }
  }

  .tns-item {
    width: 100%;
  }
}

.projcardcarrousel {
  width: 100%;
  overflow-x: auto;
  display: block;
  white-space: nowrap;
  padding: 50px 0;

  @include responsive-down (mobile-extra-large) {
    padding: 30px 0;
  }

  &__img {
    width: 80%;
    height: 550px;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 20px 0 0;

    &:last-child {
      margin-right: 0;
    }

    @include responsive-down(tablet) {
      width: 90%;
      height: 450px;
    }

    @include responsive-down(tablet-small) {
      height: 400px;
    }

    @include responsive-down(mobile) {
      height: 350px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition (all 1.5s ease 0s);
    }

    &:first-child {
      margin-left: 30px;

      @include responsive-down(mobile-large) {
        margin-left: 20px;
      }

      @include responsive-down(mobile-extra-large) {
        margin-left: 0;
      }
    }
  }
}

.projcarddescription {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 0;

  @include responsive-down(mobile-extra-large) {
    text-align: left;
    max-width: 100%;
    margin: 0 20px;
    padding: 30px 0;
  }

  &__title {
    font-family: $font-operetta;
    line-height: 1.4;
    text-align: center;
    font-size: 28px;

    @include responsive-down(tablet-extra-small) {
      font-size: 26px;
    }

    @include responsive-down(mobile-extra-large) {
      text-align: left;
    }

    @include responsive-down(mobile) {
      font-size: 23px;
    }

    @include responsive-down(mobile-extra-small) {
      font-size: 20px;
    }
  }
}

.projcardgallery {
  &__col2 {
    display: flex;
    margin: 50px 30px 0;

    @include responsive-down(mobile-extra-large) {
      margin: 0;
    }
  }

  &__col2img {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;

    &:nth-child(2n+1) {
      margin: 0 20px 0 0;

      @include responsive-down(mobile-extra-large) {
        margin: 0;
      }
    }

    &:nth-child(2n) {
      margin: 0 0 0 20px;

      @include responsive-down(mobile-extra-large) {
        margin: 0;
      }
    }

    &::before {
      content: "";
      float: left;
      width: 100%;
      padding-top: 90%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__col1 {
    margin: 100px 0 30px;
    width: 100%;

    .container {
      @include responsive-down(mobile-extra-large) {
        margin: 0;
        padding: 0;
      }
    }

    @include responsive-down(mobile-extra-large) {
      margin: 0;
      padding: 0;
    }
  }

  &__col1img {
    height: 600px;
    width: 100%;

    @include responsive-down(tablet) {
      height: 500px;
    }

    @include responsive-down(tablet-extra-small) {
      height: 400px;
    }

    @include responsive-down(mobile-extra-large) {
      height: 350px;
    }

    @include responsive-down(mobile-large) {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--nomargin {
    .projcardgallery__col1 {
      margin: 30px 0 50px;

      .projcardgallery__col1img {
        height: auto;

        img {
          object-fit: contain;
        }
      }
    }
  }
}

.projcardnavprojects {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;

  @include responsive-down(mobile-large) {
    padding: 40px 0 0;
  }

  &__a {
    color: $primary;
    position: relative;
    font-size: 16px;
    font-weight: 600;

    @include responsive-down(mobile-extra-large) {
      font-size: 14px;
    }

    @include responsive-down(mobile-large) {
      display: none;
    }

    &::before {
      content: "";
      height: 1px;
      width: 0;
      background-color: $primary;
      position: absolute;
      bottom: 0;

      @include transition (all 0.4s ease-in);
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  &__arrow {
    display: none;
    color: $primary;
    position: relative;
    font-size: 16px;
    font-weight: 600;

    @include responsive-down(mobile-large) {
      display: block;
    }
  }

  .icon-projects {
    color: $primary;
    font-size: 30px;

    &:hover {
      color: $mid-grey;
    }
  }
}
