/* Layouts: Pages: Error 404
--------------------------------------------------------------------------------------------------------------------- */
.page-error404 {
  height: 100%;
}

.error404 {
  &__content {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    position: relative;

    @include responsive-down(mobile-large) {
      min-height: 100%;
      padding: 40px 0 250px;
    }

    .btn {
      margin: 30px 0 0;
    }
  }

  &__logo {
    width: 220px;
    margin: 100px 0;

    @include responsive-down(tablet-small) {
      margin: 100px 0 80px;
    }

    @include responsive-down(mobile-large) {
      margin: 60px 0;
    }

    @include responsive-down(mobile) {
      margin: 60px 0 50px;
    }
  }

  &__number {
    font-weight: 700;
    font-size: 370px;
    position: absolute;
    color: $mid-grey;
    opacity: 0.1;
    margin: 0;
    top: -40px;
    z-index: -1;

    @include responsive-down(tablet) {
      font-size: 300px;
      top: 10px;
    }

    @include responsive-down(tablet-small) {
      font-size: 250px;
      top: 40px;
    }

    @include responsive-down(mobile-large) {
      font-size: 240px;
      top: 20px;
    }

    @include responsive-down(mobile) {
      font-size: 170px;
      top: 70px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    margin: 0;
  }

  &__text {
    font-size: 26px;
    margin: 0;
    font-family: $font-operetta;
    text-align: center;
    line-height: 1.5;

    @include responsive-down(mobile-large) {
      font-size: 21px;
    }

    @include responsive-down(mobile) {
      max-width: 200px;
    }
  }
}
