/* Layouts: Footer
--------------------------------------------------------------------------------------------------------------------- */
.footer {
  min-height: 350px;
  width: 100%;
  background-color: $primary;
  padding: 60px 0 30px;

  @include responsive-down(mobile-extra-large) {
    height: auto;
  }

  @include responsive-down(mobile) {
    height: auto;
    padding: 15px 0 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0 30px;

    @include responsive-down(mobile-large) {
      text-align: left;
      flex-direction: column;
    }

    &__img {
      display: inline-block;
      margin: 0 0 30px;

      @include responsive-down(mobile) {
        margin: 0 0 20px;
      }

      picture {
        display: block;
      }

      img {
        width: 130px;
        object-fit: initial;

        @include responsive-down(mobile) {
          width: 270px;
        }
      }
    }
  }

  &-logo {
    // @include responsive-down(mobile-extra-large) {
    //   order: 1;
    // }
  }

  &-nav {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include responsive-down(mobile) {
      padding: 50px 0 40px;
    }

    @include responsive-down(tablet-extra-small) {
      max-width: 420px;
    }

    @include responsive-down(mobile-large) {
      order: 3;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: none;
      width: 100%;
      padding: 20px 0 10px;
    }

    @include responsive-down(mobile) {
      margin: 10px 0;
      max-width: none;
      width: 100%;
    }

    &__item {
      font-family: $font-operetta;
      padding: 0 0 5px;
      font-size: 18px;
      color: white;
      display: inline-block;
      position: relative;

      @include responsive-down(tablet-small) {
        margin: 0 0 0 40px;
      }

      @include responsive-down(mobile-large) {
        margin: 0 30px 0 0;
      }

      @include responsive-down(mobile) {
        margin: 0 40px 0 0;
      }

      &::before {
        content: "";
        width: 0;
        height: 1px;
        background-color: white;
        position: absolute;
        bottom: 4px;
        left: 0;

        @include transition (all 0.4s ease 0s);
      }

      &:hover {
        &::before {
          width: 100%;
        }
      }

      a {
        color: white;
      }

      @include responsive-down(mobile) {
        padding: 0 0 0 50px;
      }

      @include responsive-down(mobile) {
        padding: 0;
        font-size: 20px;
        margin: 0 15px 20px 0;
        width: calc(100% / 2 - 70px);
      }

      @include responsive-down(mobile-extra-small) {
        margin: 0 45px 20px 0;
        width: calc(100% / 2 - 70px);
      }

      @include responsive-down(mobile-extra-small) {
        width: calc(100% / 2 - 50px);
      }
    }
  }

  &-social {
    margin-top: auto;

    img {
      padding-left: 15px;

      @include responsive-down(mobile) {
        padding-left: 0;
        padding-right: 15px;
      }
    }

    &__icon {
      color: white;
      padding: 0 10px 0 0;

      @include responsive-down(mobile) {
        padding: 0 10px 0 0;
      }

      i {
        font-size: 18px;
      }

      &:hover {
        color: $link-footer;
      }
    }
  }

  &-terms {
    margin-top: auto;
    text-align: right;

    @include responsive-down(mobile) {
      text-align: left;
    }

    a {
      font-size: 14px;
      color: $link-footer;
      text-decoration: underline;
      padding: 0 0 0 20px;

      @include responsive-down(mobile) {
        padding: 0 20px 0 0;
        font-size: 12px;
      }

      &:hover {
        color: $light-grey;
      }
    }

    p {
      color: $link-footer;
      margin: 8px 0;
      font-size: 12px;
      line-height: 1.3;

      @include responsive-down(mobile) {
        font-size: 8px;
      }

      .link {
        padding: 0;
        font-size: 12px;
        text-decoration: none;

        @include responsive-down(mobile) {
          font-size: 9px;
        }

        &:hover {
          color: $light-grey;
        }
      }
    }
  }

  #sib-form {
    position: relative;
  }

  .sib-form {
    background-attachment: initial;
    padding: 0;
    font-family: $font-urw;
    max-width: 320px;

    @include responsive-down(tablet-small) {
      padding: 0 0 0 40px;
    }

    @include responsive-down(mobile-large) {
      max-width: none;
      width: 100%;
      padding: 30px 0 10px;
    }

    &-block {
      padding: 0;

      &__button {
        padding: 10px 25px;
        font-size: 13px;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        display: inline-block;
        position: absolute;
        right: -1px;
        top: 0;
        color: $primary;
        background-color: $white;
        overflow: hidden;
        z-index: 3;
        min-height: initial;
        height: 100%;

        @include transition(all 0.3s ease-in);

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 0;
          left: 0;
          bottom: 0;
          z-index: 0;
          background-color: $primary;

          @include transition(all 0.3s ease-in);
        }

        &__text {
          display: block;
          padding: 0 0 2px;
          position: relative;
          overflow: hidden;

          .btnspan {
            display: block;
            position: relative;
            transform-origin: left top;

            @include transition(transform 0.9s cubic-bezier(0.19, 1, 0.22, 1));

            &::after {
              content: attr(data-text);
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              transform-origin: left top;

              @include transition(transform 0.9s cubic-bezier(0.19, 1, 0.22, 1));
            }
          }
        }

        &:hover {
          color: $white;
          background-color: $primary;
          border-left: 1px solid #fff;
          border-right: 1px solid #fff;

          @include transition(all 0.3s ease-in);
        }
      }

      .entry__error.entry__error--primary {
        margin: 0;
        padding: 0;
        font-size: 12px;
        line-height: 1.2;
      }

      .form__entry.entry_mcq {
        padding: 10px 0;
      }
    }

    .sib-form-message-panel__text {
      padding: 5px 0;
    }

    #success-message.sib-form-message-panel {
      margin: 0;
      padding: 0;
      border: none;

      &--active {
        .sib-notification__icon {
          width: 17px;
          margin: 0 6px 0 0;
          color: $success;
        }

        .sib-form-message-panel__inner-text {
          font-size: 12px;
          color: $success;
          padding: 5px 0;
          line-height: 1.2;
          white-space: nowrap;

          @include responsive-down(tablet) {
            white-space: inherit;
          }
        }
      }
    }

    #error-message.sib-form-message-panel {
      margin: 0;
      padding: 0;
      border: none;

      &--active {
        .sib-notification__icon {
          width: 17px;
          margin: 0 6px 0 0;
          color: $warning;
        }

        .sib-form-message-panel__inner-text {
          font-size: 12px;
          color: $warning;
          padding: 5px 0;
          line-height: 1.2;
          white-space: nowrap;

          @include responsive-down(tablet) {
            white-space: inherit;
          }
        }
      }
    }

    .entry__field {
      height: 44px;
      border: 1px solid white;
      position: relative;
      font-size: 14px;
      color: $primary;
      z-index: 0;
      background-color: transparent;
      border-radius: 0;
      border-bottom: 1px solid $white;
      margin: 0 0 10px;

      &:focus-within {
        box-shadow: none;
      }

      .input {
        width: 100%;
        height: 100%;
        border: 0;
        outline: none;
        padding: 10px 24px 0;
        padding-left: 8px;
        font-size: 14px;
        opacity: 0.8;
        color: $white;
        z-index: 2;
        position: relative;

        &:focus {
          border: none;
        }
      }

      .label {
        display: inline;
        padding: 8px 4px 8px;
        box-sizing: content-box;
        position: absolute;
        top: 2px;
        left: 5px;
        transition: top 0.2s, font-size 0.2s;
        color: $white;
        z-index: 2;
        font-size: 14px;
      }

      &--error {
        color: $warning;
        border-bottom: 1px solid $warning;

        .label {
          color: $warning;
        }
      }
    }

    .inputfocus + .label,
    .input:focus + .label {
      top: -2px;
      font-size: 9px;
    }

    .entry__label {
      font-size: 14px;
      color: white;
      font-family: $font-operetta;
    }

    .text__accepted {
      font-size: 12px;
      color: white;
      line-height: 1.3;
    }

    .entry__choice {
      .checkbox {
        min-height: initial;
        border-radius: 0;
      }

      .input_replaced:checked + .checkbox_tick_positive {
        background-color: $primary;
        border-color: $white;

        &::before {
          top: 3px;
          left: 8px;
          width: 5px;
          height: 8px;
          border: solid $white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      .input_replaced:focus + .checkbox {
        box-shadow: none;
      }
    }

    #sib-container {
      background-color: transparent !important;
      padding: 0 !important;
    }
  }
}
