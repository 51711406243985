/* Layouts: Collection
--------------------------------------------------------------------------------------------------------------------- */
.collecttitle {
  text-align: center;
  padding: 40px 0;

  @include responsive-down(mobile-large) {
    padding: 20px 0;
    font-size: 26px;
  }
}

.collectsection {
  &__intro {
    display: flex;
    justify-content: space-between;
    padding: 0 0 20px;
    position: relative;

    @include responsive-down(mobile-large) {
      padding: 0 0 10px;
    }

    .titleh2 {
      line-height: inherit;
    }

    .section-copylink {
      border-radius: 50px;
      padding: 0 17px;
      background-color: transparent;
      height: 45px;
      width: 45px;
      border: transparent;
      position: relative;

      @include transition(all 0.4s ease-in);

      &::before {
        content: "";
        background-color: $light-grey;
        padding: 20px;
        border-radius: 50px;
        position: absolute;
        right: 4px;
        top: 2px;
        z-index: 1;

        @include transition(all 0.4s ease-in);
      }

      &::after {
        content: "\e910";
        font-family: $font-icomoon;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 13px;
        top: 2px;
        text-align: center;
        bottom: 0;
        margin: auto;
        z-index: 1;
        font-size: 16px;
      }

      p {
        opacity: 0;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        line-height: 0;

        @include transition(all 0.3s ease-in);
      }

      &__msg {
        position: absolute;
        right: 9px;
        top: -42px;
        font-size: 14px;
        display: none;

        @include transition(all 0.4s ease-in);

        &--active {
          display: block;
        }
      }

      @include responsive-up(tablet) {
        &:hover {
          color: $primary;
          background-color: $light-grey;
          width: 120px;

          @include transition(all 0.4s ease-in);

          p {
            opacity: 1;

            @include transition(all 0.4s ease-in 0.3s);
          }

          &::before {
            background-color: white;
          }
        }
      }

      @include responsive-down(tablet) {
        &:focus {
          color: $primary;
          background-color: $light-grey;
          width: 120px;

          @include transition(all 0.4s ease-in);

          p {
            opacity: 1;

            @include transition(all 0.4s ease-in 0.3s);
          }

          &::before {
            background-color: white;
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding: 0 0 50px;

    .item-collection {
      width: calc(100% / 4 - 40px);
      margin: 0 20px 40px;
      min-height: 300px;

      @include responsive-down(tablet-extra-large) {
        width: calc(100% / 3 - 40px);
      }

      @include responsive-down(tablet) {
        width: calc(100% / 2 - 40px);
      }

      @include responsive-down(mobile-large) {
        width: 100%;
      }

      &__onebtn {
        @include responsive-up(tablet) {
          &:hover {
            color: $primary;
          }
        }

        @include responsive-down(tablet) {
          &:focus {
            color: $primary;
          }
        }
      }
    }
  }
}

.collectmodal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 9999;
  background-color: $black07;

  &__content {
    position: relative;
    display: flex;
    margin: auto;
    z-index: 1;
    bottom: 0;
    top: 0;
    align-items: center;
    padding: 0 20px;

    i {
      color: white;
      font-size: 45px;

      &:first-child {
        padding: 0 50px 0 0;
      }

      &:last-child {
        padding: 0 0 0 50px;
      }
    }
  }

  &__close {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: transparent;
    border: transparent;
    padding: 0;

    @include responsive-up(tablet) {
      &:hover {
        background-color: transparent;
      }
    }

    @include responsive-down(tablet) {
      &:focus {
        background-color: transparent;
      }
    }

    span {
      position: absolute;
      height: 2px;
      width: 30px;
      right: 0;
      background-color: white;
      border-radius: 12px;
      opacity: 1;
      z-index: 2;
    }

    .close {
      position: relative;
    }

    &-right {
      top: 0;

      @include transform(rotate(45deg));
    }

    &-left {
      top: 0;

      @include transform(rotate(-45deg));
    }
  }

  &__img {
    margin: auto;
    display: grid;
    max-width: 700px;

    img {
      width: 100%;
    }
  }

  &--opened {
    display: flex;
  }
}

body.menu--opened {
  .collectionnav {
    z-index: 1;

    @include transition (0.1s ease-in);
  }
}

.pswp {
  &__top-bar,
  &__button-cnt {
    height: 60px;
  }

  &__counter {
    display: none;
  }

  &__button {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 50;
    border: 0;
    color: white;
    font-size: 30px;
    width: 30%;
    height: calc(100% - 100px);
    padding: 20px;

    @include responsive-down(mobile) {
      width: 15%;
      color: black;
    }

    &--arrow--left {
      left: 0;

      &::before {
        font-family: $font-icomoon;
        content: "\e901";
        display: block;

        @include transition(all 0.3s ease-in);
        @include transform(translateX(0));
      }

      @include responsive-up(tablet) {
        &:hover {
          &::before {
            @include transform(translateX(-10px));
          }
        }
      }

      @include responsive-down(mobile) {
        color: black !important;
        width: 32px;
        padding: 0;
      }
    }

    &--arrow--right {
      right: 0;
      left: inherit;

      &::before {
        font-family: $font-icomoon;
        content: "\e902";
        display: block;

        @include transition(all 0.3s ease-in);
        @include transform(translateX(0));
      }

      @include responsive-up(tablet) {
        &:hover {
          &::before {
            @include transform(translateX(10px));
          }
        }
      }

      @include responsive-down(mobile) {
        color: black !important;
        width: 32px;
        padding: 0;
      }
    }

    &--fs {
      display: none;
    }

    &--zoom {
      display: none;
    }

    &--close {
      color: white;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 200;
      left: inherit;
      height: auto;
      width: auto;

      &::before {
        font-family: $font-icomoon;
        content: "\e90d";
        width: 30px;
        color: white;
        display: block;

        @include transition(all 0.3s ease-in);
        @include transform(scale(0.8) rotate(45deg));
      }

      @include responsive-up(tablet) {
        &:hover {
          &::before {
            @include transform(scale(1) rotate(45deg));
          }
        }
      }
    }
  }

  &__scroll-wrap {
    width: calc(100% - 40px) !important;
    margin: 20px !important;
  }

  &__bg {
    opacity: 0.7 !important;
  }
}

.js-collectsection-loading {
  .item-collection {
    .js-productslider {
      .item-collection__img {
        opacity: 0;
        display: none;

        &:first-child {
          opacity: 1;
          display: flex;
        }
      }
    }

    .btn-search {
      opacity: 0;
    }
  }
}
